@font-face {
    font-family: "FrutigerLight";
    src: url("/fonts/FrutigerLTStd-Light.woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "FrutigerLight";
    src: url("/fonts/FrutigerLTStd-Bold.woff2");
    font-weight: 700;
    font-style: bold;
}

@font-face {
    font-family: "FrutigerRoman";
    src: url("/fonts/FrutigerLTStd-Roman.woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "FrutigerRoman";
    src: url("/fonts/FrutigerLTStd-Black.woff2");
    font-weight: 700;
    font-style: bold;
}

@tailwind base;

@layer base {
    #editor-content-body {
        html {
            color: theme("colors.brand-black");
            -webkit-font-smoothing: antialiased;
            text-size-adjust: 100%;
            text-rendering: optimizelegibility;
        }

        body {
            color: theme("colors.brand-black");
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: theme("fontFamily.heading");
            font-weight: bold;
        }

        h1 {
            font-size: 24px;
            line-height: 28px;

            @media only screen and (min-width: theme("screens.lg")) {
                font-size: 32px;
                line-height: 36px;
            }

            &.heading1-huge {
                font-size: 32px;
                line-height: 36px;

                @media only screen and (min-width: theme("screens.lg")) {
                    font-size: 50px;
                    line-height: 56px;
                }
            }
        }

        h2 {
            font-size: 18px;
            line-height: 24px;

            @media only screen and (min-width: theme("screens.lg")) {
                font-size: 28px;
                line-height: 32px;
            }
        }

        h3 {
            font-size: 16px;
            line-height: 20px;

            @media only screen and (min-width: theme("screens.lg")) {
                font-size: 24px;
                line-height: 28px;
            }
        }

        h4 {
            font-size: 14px;
            line-height: 18px;

            @media only screen and (min-width: theme("screens.lg")) {
                font-size: 18px;
                line-height: 22px;
            }
        }

        h5 {
            font-size: 16px;
            line-height: 20px;
        }

        h6 {
            font-size: 12px;
            line-height: 18px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }

        a:not([type="button"]) {
            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='-1 -1 2 2'><circle r='1' fill='%230079C2' /></svg>");
            list-style-position: inside;

            li {
                margin-bottom: 15px;

                &:before {
                    content: "";
                    width: 2px;
                    display: inline-block;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        ol {
            list-style-type: decimal;
            list-style-position: inside;

            li {
                margin-bottom: 15px;

                &:before {
                    content: "";
                    width: 2px;
                    display: inline-block;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        blockquote {
            font-size: 18px;
            line-height: 26px;
            border-left: 4px solid theme("colors.brand-secondary");
            color: theme("colors.brand-black");
            padding-left: 30px;
        }

        hr {
            border: 0;
            border-top: 1px solid theme("colors.brand-grey");
        }

        table:not([class^="docblock-argstable sb-unstyled"]) {
            width: 100%;
            border-collapse: collapse;
            font-size: 16px;
            line-height: 24px;

            th,
            td {
                padding: 15px;
                text-align: left;
            }

            th {
                background-color: theme("colors.brand-secondary");
                color: theme("colors.brand-white");
                font-weight: bold;
            }

            tr:nth-child(odd) {
                td {
                    background: theme("colors.brand-light-grey");
                }
            }
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .ory-row {
            h1 {
                &.heading1-huge {
                    font-size: 50px;
                    line-height: 56px;
                }
            }
            .content__text {
                .text-tiny {
                    font-size: 12px;
                    line-height: 18px;
                }

                .text-small {
                    font-size: 14px;
                    line-height: 20px;
                }

                ol,
                ul {
                    li {
                        margin-bottom: 0;
                    }
                }
            }

            .content__form form {
                display: flex;
                flex-direction: column;
                row-gap: 15px;

                div {
                    margin-bottom: 0px;
                }

                h3 {
                    margin-bottom: 0;
                }

                button {
                    align-self: flex-start;
                }

                input[type="text"],
                input[type="email"],
                input[type="password"],
                input[type="number"],
                input[type="date"],
                input[type="url"],
                textarea,
                select {
                    background-color: theme("colors.brand-white") !important;
                    border-radius: theme("borderRadius.DEFAULT") !important;

                    &:hover {
                        background-color: theme(
                            "colors.brand-white"
                        ) !important;
                    }
                }
            }

            a:not([type="button"]) {
                color: theme("colors.brand-secondary");
                text-decoration: underline;

                &:hover {
                    color: theme("colors.brand-secondary-darken");
                }
            }
        }
    }
}

@tailwind components;
@tailwind utilities;
